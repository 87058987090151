import styled from "styled-components";
import { BP, Colors, Rem } from "../../commons/Theme";
import {
  StyledButton,
  StyledContainer,
  StyledParagraph,
  StyledTitle,
} from "../globalstyle";

export const Wrapper = styled.div`
  flex-direction: column;
`;

export const CardsWrapper = styled(StyledContainer)`
  background-color: ${Colors.grayLight};
  padding-top: ${Rem(15)};
  padding-bottom: ${Rem(15)};
  flex-direction: column;
`;

export const Subtitle = styled(StyledTitle)`
  align-self: flex-start;
  letter-spacing: 3px;
  text-transform: uppercase;
`;

export const Cards = styled(StyledContainer)`
  display: flex;
  flex-wrap: wrap;
  z-index: 9;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 33.3333%;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
  z-index: 1000;
`;
export const CardImage = styled.img`
  width: 100px;
  height: 100px;
`;

export const CardTitle = styled(StyledTitle)`
  text-transform: uppercase;
`;

export const CardDescription = styled(StyledParagraph)`
  width: 220px;
  @media (${BP.tablet}) {
    width: 300px;
  }
`;

export const CardButton = styled(StyledButton)`
  width: 160px;
  z-index: 9;
  align-self: center;
`;
