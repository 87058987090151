import React from "react";
import { Colors } from "../../commons/Theme";
import {
  StyledContainer,
  StyledLabel,
  StyledSpacer,
  StyledTitle,
} from "../globalstyle";
import {
  Card,
  CardButton,
  CardDescription,
  CardImage,
  Cards,
  CardsWrapper,
  CardTitle,
  Subtitle,
  Wrapper,
} from "./styles";
import Link from "../Link/Link";

/**
 * @typedef {{id: string; title: string; subtitle: string; items: CardItem[]}} Props
 * @typedef {{title: string; description: string; img: Image; cta?: {label: string; url: string}}} CardItem
 * @typedef {{url: string; alt: string}} Image
 *
 * @param props {Props}
 */

const FeatureCardsList = ({
  id, title, subtitle, items,
}) => {
  const cards = items.filter((e) => !e.disabled).map((card, index) => {
    const {
      title: cardTitle, description, cta, img,
    } = card;

    return (
      <Card key={index}>
        <CardImage src={img.url} alt={img.alt} />
        <CardTitle center mini font="sans">
          {cardTitle}
        </CardTitle>
        <CardDescription small center font="sans">
          {description}
        </CardDescription>
        {cta ? (
          <>
            <StyledSpacer size={1} style={{ flexGrow: 1 }}/>
            <Link
              to={cta.url}>
              <CardButton>
                <StyledLabel>{cta.label}</StyledLabel>
              </CardButton>
            </Link>
          </>
        ) : null}
      </Card>
    );
  });

  return (
    <Wrapper id={id}>
      <StyledContainer>
        <StyledTitle
          center
          dangerouslySetInnerHTML={{ __html: title }}
          font="sans"
        />
      </StyledContainer>
      <StyledSpacer size={15} />
      <CardsWrapper>
        <Subtitle mini color={Colors.grayPantone} font="sans">
          {subtitle}
        </Subtitle>
        <Cards>{cards}</Cards>
      </CardsWrapper>
    </Wrapper>
  );
};

export default FeatureCardsList;
